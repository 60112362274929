<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.listenerResize();
    this.$nextTick(() => {
      this.banCtrlMouseWheel();
      window.addEventListener("resize", this.listenerResize, false);
    });
  },
  watch: {
    $route: {
      handler(nVal, oVal) {
        let str = "";
        if (nVal.name == "login") {
          str = this.$t(`label.login.login_btn`);
        } else {
          if (
            nVal.matched.filter(
              (f) => f.name == "receiver" || f.name == "status"
            ).length
          ) {
            this.$store.commit("config/SET_MODEL", "config");
          } else {
            this.$store.commit("config/SET_MODEL", "");
            str = this.$t(`routes.${nVal.name}`);
          }
        }
        if (str || nVal.meta.title) {
          document.title = str || nVal.meta.title;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    listenerResize() {
      this.$store.dispatch("config/getDeviceInfo");
    },
    banCtrlMouseWheel() {
      window.addEventListener(
        "mousewheel",
        function (event) {
          if (event.ctrlKey === true || event.metaKey) {
            event.preventDefault();
          }
        },
        { passive: false }
      );

      //firefox
      window.addEventListener(
        "DOMMouseScroll",
        function (event) {
          if (event.ctrlKey === true || event.metaKey) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
    },
  },
};
</script>
<style>
* {
  /* touch-action: none; */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
  height: 100%;
  background-color: #e6e7ea;
  /* background-color: $main_color; */
}

.container {
  height: 100%;
}
</style>
