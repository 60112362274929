
import store from '@/store/index.js'
import {
  getItem,
  setItem
} from "@/utils/localStorage"
import router from '../router';

(async () => {

  store.dispatch('config/getDeviceInfo')

  const fullRoute = getItem('apiFullRoute') ? JSON.parse(getItem('apiFullRoute')) : ''
  if (!store.getters['permission/routes'].length && fullRoute) {
    await router.selfAddRoutes(fullRoute);
  }
})()