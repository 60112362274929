import $ from "jquery";
import * as regExp from "./regExp";
import { setItem } from "./localStorage";
import { Message } from "element-ui";
// 获取当前时间
const getCurrentTime = function () {
  let myDate = new Date();
  let wk = myDate.getDay();
  let yy = String(myDate.getFullYear());
  let m1 = myDate.getMonth() + 1;
  let mm = String(m1 < 10 ? "0" + m1 : m1);
  let dd = String(
    myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate()
  );
  let hou = String(
    myDate.getHours() < 10 ? "0" + myDate.getHours() : myDate.getHours()
  );
  let min = String(
    myDate.getMinutes() < 10 ? "0" + myDate.getMinutes() : myDate.getMinutes()
  );
  let sec = String(
    myDate.getSeconds() < 10 ? "0" + myDate.getSeconds() : myDate.getSeconds()
  );
  let weeks = [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ];
  let week = weeks[wk];
  let nowDate = yy + "年" + mm + "月" + dd + "日";
  let nowTime = hou + ":" + min + ":" + sec;
  let nowWeek = week;

  return {
    timer: {
      nowDate,
      nowTime,
      nowWeek,
    },
    timeStr: `${nowDate} ${nowTime} ${nowWeek}`,
  };
};
// 页面大小变化
const resize = function () {
  let ratioX = $(window).width() / 1920;
  let ratioY = $(window).height() / 1080;
  setItem("ratioX", ratioX);
  setItem("ratioY", ratioY);
  $("#app").css({
    transform: "scale(" + ratioX + "," + ratioY + ")",
    //transformOrigin: "0px 0px 0px",
    transformOrigin: "left top",
    // backgroundSize: "100% 100%",
    // overflow: "hidden",
    width: "1920px",
    height: "1080px",
  });

  $("html").css({
    overflow: "hidden",
  });
};
// 简单的深拷贝
const clone = (obj) => {
  let copy;
  // 处理简单数据类型、null和undefined
  if (obj == null || typeof obj !== "object") return obj;
  // 处理日期
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }
  // 处理数组
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }
  // 处理对象
  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, attr))
        copy[attr] = clone(obj[attr]);
    }
    return copy;
  }

  return false;
};
// 获取传入参数的类型
const getType = (val) => {
  return Object.prototype.toString.call(val).split(" ")[1].replace("]", "");
};

// 获取图片转base64
const imageFileToBase64 = (file) => {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    let imgResult = "";
    reader.readAsDataURL(file);
    reader.onload = function () {
      imgResult = reader.result;
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.onloadend = function () {
      resolve(imgResult);
    };
  });
};

const exportEx = (urlText, name = "excel") => {
  return new Promise((res) => {
    const url = window.URL.createObjectURL(
      new Blob([urlText], {
        type: "application/vnd.ms-excel;charset=utf-8",
      })
    );
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.setAttribute("download", `${name}.xlsx`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    Message({
      message: "导出成功！正在下载...",
      type: "success",
      center: true,
    });
    res(true);
  });
};

const exportImage = (urlText, name = "图标") => {
  return new Promise((res) => {
    var a = document.createElement("a");
    var event = new MouseEvent("click");
    // 自定义下载后图片的名称
    a.download = name;
    a.href = urlText;
    a.dispatchEvent(event);
    Message({
      message: "导出成功！正在下载...",
      type: "success",
      center: true,
    });
    res(true);
  });
};

const isUrl = (str) => {
  if (!str) {
    return false;
  }
  return /^(?:http(s)?:\/\/)?[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'*+,;=.]+$/.test(
    str
  );
};
/**
 *
 * @param {azimuth='方位角',elevation='俯仰角',Radius='半径'} params
 * @returns
 */
const getXY = (params) => {
  const { elevation, azimuth, Radius } = params;
  let len = Math.cos((elevation * Math.PI) / 180) * Radius;
  return {
    y: Math.cos((azimuth * Math.PI) / 180) * len,
    x: Math.sin((azimuth * Math.PI) / 180) * len,
  };
};
/**
 * 复制单行内容到粘贴板
 *
 */
function copyToClip(value) {
  return navigator.clipboard.writeText(value);
}
// localStorage监听重写
function dispatchEventStroage() {
  const signSetItem = localStorage.setItem;
  localStorage.setItem = function (key, val) {
    let setEvent = new Event("setItemEvent");
    setEvent.key = key;
    setEvent.newValue = val;
    window.dispatchEvent(setEvent);
    signSetItem.apply(this, arguments);
  };
}

// 手写保留小数位
function toFixedFnForce(num, fix = 2, isforce = true) {
  // 强制保留位数   处理后为字符串
  var f = parseFloat(num);
  if (isNaN(f)) {
    return f ? f : "0";
  }
  num = +num;
  num *= Math.pow(10, fix);
  num = Math.round(num);
  f = num / Math.pow(10, fix);
  if (isforce) {
    var s = f.toString();
    var rs = s.indexOf(".");
    if (rs < 0) {
      rs = s.length;
      s += ".";
    }
    while (s.length <= rs + fix) {
      s += "0";
    }
    return s;
  }
  return f + "";
}

// 日期时间转换
const transformDate = (time, haveHMS = true) => {
    if(!time){
      return
    }
    let res = ""
    let date = new Date(time)
    let year = date.getFullYear(); // 获取年份  
    let month = date.getMonth() + 1; // 获取月份。注意，月份是从0开始的，所以需要+1  
    let day = date.getDate(); // 获取日期  
    let hours = date.getHours(); // 获取小时  
    let minutes = date.getMinutes(); // 获取分钟  
    let seconds = date.getSeconds(); // 获取秒数  
      
    // 如果月份、日期、小时、分钟或秒数是单数字，前面补一个'0'  
    month = month < 10 ? '0' + month : month;  
    day = day < 10 ? '0' + day : day;  
    hours = hours < 10 ? '0' + hours : hours;  
    minutes = minutes < 10 ? '0' + minutes : minutes;  
    seconds = seconds < 10 ? '0' + seconds : seconds;
    if(haveHMS){
      // 转化到时分秒
      res = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    } else {
      res = year + '-' + month + '-' + day;
    }
    return  res
}
// 递归处理接口数据 菜单模块
const  generateOptions = (params) => {
  let result = [];
  for (const param of params) {
    if (param.pid == 0) {
      // 判断是否为顶层节点
      let parent = {
        route: "/" + param.url,
        id: param.id,
        name: param.url,
        meta: {
          title: param.name,
          icon: param.icon,
          // sort: 1,
          // hidden: false,
          // isDetail: false
        }
      };
      parent.children = getchilds(param.id, params); // 获取子节点
      result.push(parent);
    }
  }
  console.log(result, "result")
  return result;
}
const getchilds = (value, array) => {
  const childs = [];
  for (const arr of array) {
    // 循环获取子节点
    if (arr.pid === value) {
      childs.push({
        route: "/" + arr.url,
        id: arr.id,
        name: arr.url,
        meta: {
          title: arr.name,
          icon: arr.icon,
          // sort: 1,
          // hidden: false,
          // isDetail: false
        }
      });
    }
  }
  for (const child of childs) {
    // console.log(childs, "sssss")
    // 获取子节点的子节点
    const childscopy = getchilds(child.id, array); // 递归获取子节点
    if (childscopy.length > 0) {
      child.children = childscopy;
    }
  }
  return childs;
}

export default {
  getCurrentTime,
  resize,
  clone,
  getType,
  imageFileToBase64,
  exportEx,
  exportImage,
  isUrl,
  copyToClip,
  getXY,
  regExp,
  dispatchEventStroage,
  toFixedFnForce,
  transformDate,
  generateOptions
};
