import tool from "@/utils/tool"
import { meunNav, navMode } from "../../config/project"
// 懒加载页面
const lazy = function (page, file = 'index') {
  if (page == 'wrapper') {
    return (resolve) => require([`@/layout/wrapper`], resolve)
  } else {
    return (resolve) => require([`@/views/${page}/${file}.vue`], resolve)
  }

}
// 数组转树  可根据需求自定义key和parentKey名称
const arrToTree = (arr, key = 'id', pkey = 'pid') => {
  if (!arr.length || !arr.some(f => !f.pid)) {
    return []
  }
  const allPage = tool.clone(arr)
  const tempIds = {};

  let res = [];
  allPage.forEach((f) => {
    delete f.children
    tempIds[f[key]] = f;
  });
  allPage.forEach((f) => {
    if (!f.pid) {
      res.push(f);
    } else {
      if (tempIds[f[pkey]]) {
        if (tempIds[f[pkey]].children && !tempIds[f[pkey]].children.find(_f => f[key] == _f[key])) {
          tempIds[f[pkey]].children.push(f);
        } else {
          tempIds[f[pkey]].children = [f];
        }
      }

    }
  });
  return tool.clone(res)
}
// 递归构建路由
const deepTreeGenerateRouter = (arr, level = 1) => {
  let meunList = []
  let routes = []
  let deep = []
  arr.forEach((f, i) => {
    let meunTemp = {};
    let routeTemp = {};
    if (!f.meta.sort) f.meta.sort = 0
    if (f.children && f.children.length) {
      f.children.forEach(_item => _item.meta.pRoute = f.route);
      const res = deepTreeGenerateRouter(f.children, level + 1);

      routeTemp = {
        path: level == 1 ? `/${f.route}` : `${f.route}`,
        name: `${f.route}`,
        // level == 1 ? lazy('wrapper') : lazy(f.route)
        component: getComponent({ level, route: f }),
        redirect: {
          name: res.routes.filter(f => !f.hidden)[0].name
        },
        meta: {
          ...f.meta,
          hidden: isHidden(f),
          component: level == 1 ? 'wrapper' : f.route
        },
        children: res.routes.map(_f => {
          _f.meta.parant = f.route;
          return _f
        }),
      };
      meunTemp = {
        id: `${level}_${i + 1}`,
        path: `/${f.route}`,
        name: `${f.route}`,
        meta: {
          ...f.meta,
          hidden: isHidden(f)
        },
        children: res.meunList
      };
      deep = [...deep, ...res.deep].filter(f => f);
    } else {
      if (level == 1) {
        f.meta.pRoute = f.route;
        routeTemp = {
          path: `/${f.route}Main`,
          name: `${f.route}Main`,
          redirect: {
            name: `${f.route}`
          },
          meta: {
            ...f.meta,
            component: 'wrapper'
          },
          component: getComponent({ level, route: f }),
          children: [{
            path: 'index',
            component: getComponent({
              level: level + 1,
              route: {
                ...f,
                // route: 'index',
              }
            }),
            name: f.route,
            meta: {
              ...f.meta,
              hidden: isHidden(f),
              component: f.route,
              parant: `${f.route}Main`,
            },
          },],
        };
      } else {
        routeTemp = {
          path: `${f.route}`,
          name: `${f.route}`,
          component: getComponent({ level, route: f }),
          meta: {
            ...f.meta,
            hidden: isHidden(f),
            component: f.route,
          },
        };
      }
      if (navMode == "sideTop") {
        meunTemp = {
          id: `${level}_${i + 1}`,
          path: `/${f.route}Main`,
          name: `${f.route}Main`,
          meta: {
            ...f.meta,
            hidden: isHidden(f)
          },
          children: [
            {
              id: `${level}_${i + 1}`,
              path: `/${f.route}`,
              name: `${f.route}`,
              meta: {
                ...f.meta,
                hidden: isHidden(f)
              },
            }
          ]
        };
      } else {
        meunTemp = {
          id: `${level}_${i + 1}`,
          path: `/${f.route}`,
          name: `${f.route}`,
          meta: {
            ...f.meta,
            hidden: isHidden(f)
          },
        };
      }

    }

    routes.push(routeTemp)
    meunList.push(meunTemp)
  })
  let meunListTemp = meunList.sort((a, b) => b.meta.sort - a.meta.sort);
  let routesTemp = routes.sort((a, b) => b.meta.sort - a.meta.sort);
  deep.push(meunListTemp.filter(f => !f.meta.hidden).length ? meunListTemp.filter(f => !f.meta.hidden)[0].name : '')
  return {
    meunList: meunListTemp,
    routes: routesTemp,
    deep
  }

}
// 处理组件实例
const getComponent = (params) => {
  let res = null;
  const { route, level } = params;
  if (level == 1) {
    res = lazy('wrapper')
  } else {
    res = lazy(route.meta.pRoute, route.route)
  }

  return res
}
// 处理路由是否在导航栏隐藏
const isHidden = (route) => {
  let res = false;
  const { meta } = route;
  if (meta.isDetail) {
    res = true;
  } else {
    res = meta.hidden || false
  }
  return res
}
/**
 * 合并路由方法，若主次路由有冲突，以主要路由为主
 * @param {*} mainRoute  主要路由
 * @param {*} minorRoute   次要路由
 * @returns 
 */
const mergeRoutes = (mainRoute = [], minorRoute = []) => {
  if (!mainRoute || !mainRoute.length) {
    return minorRoute
  }
  let arrTemp = {};
  let { resTemp: routesObj } = treeToObject(minorRoute);
  let hasPid = [];
  console.log(mainRoute, routesObj);
  // 主要路由处理
  mainRoute.forEach(f => {
    arrTemp[f.route] = f;
    if (!f.meta.pid) {
      routesObj[f.route] = f;
    } else {
      hasPid.push(f);
    }
  })
  hasPid.forEach(f => {
    if (arrTemp[f.meta.pid]) {
      if (arrTemp[f.meta.pid].children && arrTemp[f.meta.pid].children.length) {
        arrTemp[f.meta.pid].children.push(f);
      } else {
        arrTemp[f.meta.pid].children = [f]
      }
    } else {
      let keyTemp = Object.keys(routesObj).find(k => {
        return k.indexOf(`${f.meta.pid}_${f.route}`) === -1 ? false : true
      });
      if (keyTemp) {
        if (routesObj[keyTemp].children) {
          routesObj[keyTemp].children.push(f)
        } else {
          routesObj[keyTemp].children = [f]
        }
      } else {
        if (routesObj[f.meta.pid]) {
          if (routesObj[f.meta.pid].children) {
            routesObj[f.meta.pid].children.push(f)
          } else {
            routesObj[f.meta.pid].children = [f]
          }
        }
      }
    }
  });
  return Object.keys(routesObj).map(k => {
    if (k.split("_").length == 1) {
      return routesObj[k]
    } else {
      return ''
    }
  }).filter(f => f)
}
// 中转方法
const generateRouter = (arr) => {
  const orgRoutes = mergeRoutes(arr, meunNav)
  return {
    ...deepTreeGenerateRouter(orgRoutes),
    orgRoutes
  }
}
// 首次静态页面构建
const handelStaticRoutes = (arr) => {
  let orgRoutes = null;
  if (arr) {
    orgRoutes = mergeRoutes('', arr)
  } else {
    orgRoutes = mergeRoutes(arr, meunNav)
  }
  return {
    ...deepTreeGenerateRouter(orgRoutes),
    orgRoutes
  }
}
// 处理接口返回得路由结构使结构统一
const handleRoutes = (routes) => {
  if (!routes || !routes.length) {
    return []
  }
  let res = {};
  let hasPid = [];
  // 区分是否有pid
  routes.forEach(f => {
    if (!f.pid) {
      res[f.id] = f;
    } else {
      hasPid.push(f)
    }
  })
  // 统一结构
  hasPid.forEach(f => {
    if (res[f.pid]) {
      f.pid = res[f.pid].route;
    }
    if (!res[f.route]) {
      res[f.route] = f;
    }
  })

  return Object.values(res).map(f => {
    return {
      route: f.route,
      meta: f
    }
  })
}
// tree转Obj  
const treeToObject = (data, level = 1) => {
  let temp = [];
  let res = {};
  data.forEach(item => {
    if (level == 1) {
      res[item.route] = item;
    }
    if (item._route) {
      item._route.push(item.route)
    } else {
      item._route = [item.route]
    }
    temp.push(item);
    if (item.children && item.children.length) {
      item.children.forEach(f => {
        f._route = [...item._route];
      })
      const { resTemp, routeTemp } = treeToObject(item.children, level + 1);
      routeTemp.forEach(_item => {
        res[_item._route.join("_")] = _item;
      })
      res = { ...res, ...resTemp }
    }
  });
  return { resTemp: res, routeTemp: temp };
}
export {
  generateRouter,
  arrToTree,
  handelStaticRoutes,
  handleRoutes
}