const validValueNumAndLetter = (val) => {
    return /^[A-Za-z0-9]+$/.test(val)
}
const validIp = (val) => {
    return /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/.test(val)
}
const validPhone = (val) => {
    return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(val)
}
const validPwd = (val) => {
    return /^(?=.*[a-zA-Z])(?=.*[0-9])|(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_\-+=~`{}[\]|:;"'<>,.?/])|(?=.*[0-9])(?=.*[!@#$%^&*()_\-+=~`{}[\]|:;"'<>,.?/])/.test(val)
}
export {
    validValueNumAndLetter,
    validIp,
    validPhone,
    validPwd,
}