import axios from "axios";
import { Loading, Message } from "element-ui";
import router from "../router/index.js";
import { getCookie, delCookie } from "@/utils/cookie.js";
import { tokenName, baseUrl } from "../config/project";
let loading;

function startLoading() {
  loading = Loading.service({
    lock: true,
    text: "加载中....",
    background: "rgba(0, 0, 0, 0.7)",
  });
}

function endLoading() {
  if (loading) {
    loading.close();
  }
}
//超时2分钟
// console.log(axios.defaults)
axios.defaults.timeout = 1000 * 60 * 2;
axios.defaults.baseURL = baseUrl;
// 请求拦截
axios.interceptors.request.use(
  (confing) => {
    let token
    // let token;
    // if (getCookie(tokenName)) {
    //   token = getCookie(tokenName);
    // }
    if (localStorage.getItem("token")) {
      token = JSON.parse(localStorage.getItem("token"));
    }

    //设置请求头
    if (token) {
      confing.headers.Authorization = "Bearer " + token;
      // confing.headers.Authorization = "Bearer 7zZhOuPQO3oIrY1bBhyr0u2A9LD7Bndf8ivnHr12/l1rTQGe1Tb/NM9OroirjXXz";
    }
    // confing.headers.contentType = ;
    return confing;
  },
  (error) => {
    Message.error("服务异常！");
    endLoading();
    return Promise.reject(error);
  }
);

//响应拦截
axios.interceptors.response.use(
  (response) => {
    endLoading();
    //当返回信息为未登录或者登录失效的时候重定向为登录页面
    // if(response.data.code == 'W_100004' || response.data.message == '用户未登录或登录超时，请登录！'){
    //   router.push({
    //     path:"/",
    //     querry:{redirect:router.currentRoute.fullPath}//从哪个页面跳转
    //   })
    // }
    // 获取状态码
    const { code } = response.data;
    if (code === 422 || code === 401) {
      console.log(code, "code")
      Message.error("请重新登录");
      //清除token
      delCookie("token");
      delCookie("userMessObj");
      //跳转到登录页面
      router.push("/login");
    }
    if (response.data.code != 200 && response.code === 1) {
      // console.log(response.data.metadata.msg, "000");
      console.log(code, "code")
      Message.error(
        response.data.metadata.msg ||
        response.data.msg ||
        response.data.message ||
        response.data
      );
      return false;
    } 
    else if(code === 403){
      return response.data; 
    }
    else if (code && code !== 200 && code !== 0 && code !== 401) {
      console.log(code, "code")
      Message.error(
        response.data.msg || response.data.message
      );
    } else {
      return response.data;
    }
  },
  (error) => {
    console.log(error, "error");
    // console.log(1)
    Message.error(
      error.response
        ? error.response.data.metadata
          ? error.response.data.metadata.msg
          : error.response.data.message
        : "网络错误"
    );
    if (error.response.data.code == 401 || error.response.data.code == 402) {
      endLoading();
      delCookie("token");
      delCookie("userMessObj");
      //跳转到登录页面
      router.push("/login");
    }
    return Promise.reject(error);
    // return error.response.data
  }
);
export default axios;
