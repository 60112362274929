const title = "后台模板后台模板后台模板";
const logo = require("../assets/images/public/logo.png");
// const baseUrl = 'http://1.92.83.247:18900/sjkf/v1'; // 开发

const baseUrl = 'https://lssj.allynav.cn/sjkf/v1'; // 测试环境


// 默认皮肤
const defaultSkin = [
  {
    label: "默认",
    topColor: "#000000",
    topBg: ["#ffffff", "#ffffff"],
    sideColor: "#000000",
    sideBg: ["#ffffff", "#ffffff"],
    isDefault: true,
    checked: true,
  },
];

// 管理端侧边栏
// const meunNav = [
//   {
//     route: "equipManage",
//     meta: {
//       title: "设备管理",
//       icon: "icon-shebeiguanli",
//       sort: 3,
//       hidden: false,
//       oneChildrenHidden: true,
//       isDetail: false,
//     },
//     children: [
//       {
//         route: "equipManage",
//         meta: {
//           title: "设备管理",
//           icon: "icon-shebeiguanli",
//           sort: 3,
//           hidden: false,
//           isDetail: false,
//         }
//       },
//     ]
//   },
//   {
//     route: "permissionManage",
//     meta: {
//       title: "账号管理",
//       icon: "icon-quanxianshenhe",
//       sort: 2,
//       hidden: false,
//       oneChildrenHidden: true,
//       isDetail: false,
//     },
//     children: [
//       {
//         route: "permissionManage",
//         meta: {
//           title: "账号管理",
//           icon: "icon-quanxianshenhe",
//           sort: 2,
//           hidden: false,
//           isDetail: false,
//         }
//       },
//     ]
//   },
//   {
//     route: "baseInfo",
//     meta: {
//       title: "基本信息",
//       icon: "icon-quanxianshenhe",
//       sort: 2,
//       hidden: true,
//       oneChildrenHidden: true,
//       isDetail: false,
//     },
//     children: [
//       {
//         route: "baseInfo",
//         meta: {
//           title: "基本信息",
//           icon: "icon-quanxianshenhe",
//           sort: 2,
//           hidden: true,
//           isDetail: false,
//         }
//       },
//     ]
//   },
//   {
//     route: "developApp",
//     meta: {
//       title: "开发应用",
//       icon: "icon-quanxianshenhe",
//       sort: 2,
//       hidden: true,
//       oneChildrenHidden: true,
//       isDetail: false,
//     },
//     children: [
//       {
//         route: "developApp",
//         meta: {
//           title: "开发应用",
//           icon: "icon-quanxianshenhe",
//           sort: 2,
//           hidden: false,
//           isDetail: false,
//         }
//       },
//     ]
//   },
//   {
//     route: "document",
//     meta: {
//       title: "开发文档",
//       icon: "icon-quanxianshenhe",
//       sort: 2,
//       hidden: true,
//       oneChildrenHidden: true,
//       isDetail: false,
//     },
//     children: [
//       {
//         route: "document",
//         meta: {
//           title: "开发文档",
//           icon: "icon-quanxianshenhe",
//           sort: 2,
//           hidden: false,
//           isDetail: false,
//         }
//       },
//       {
//         route: "document",
//         meta: {
//           title: "开发文档",
//           icon: "icon-quanxianshenhe",
//           sort: 2,
//           hidden: false,
//           isDetail: false,
//         }
//       },
//       {
//         route: "introduction",
//         meta: {
//           title: "简介",
//           icon: "icon-quanxianshenhe",
//           sort: 2,
//           hidden: false,
//           isDetail: false,
//         }
//       },
//       {
//         route: "token",
//         meta: {
//           title: "生成access Token",
//           icon: "icon-quanxianshenhe",
//           sort: 2,
//           hidden: false,
//           isDetail: false,
//         }
//       },
//       {
//         route: "code",
//         meta: {
//           title: "状态码",
//           icon: "icon-quanxianshenhe",
//           sort: 2,
//           hidden: false,
//           isDetail: false,
//         }
//       },
//     ]
//   },
// ];
const meunNav = []
// 导航栏模式
const navMode = "default"; // default 只有侧边栏  sideTop 侧边和顶部都有
// 布局模式
const layoutMode = "navFull"; // navFull  顶部导航栏通栏   sideFull 侧边导航栏通栏
// 是否开启页面缓存
const useRouteCache = false;

// 表格自定义列
const tableItemName = "open_tableItem";
// 本地皮肤缓存名称
const skinName = "open_skin";
// 本地token缓存名称
const tokenName = "open_token";
// 本地重载缓存
const reLoadName = "open_reload";
// 本地翻译标识
const langFlag = "localOpenLangFlag";
// 本地菜单信息列表
const menuListName = "openMenuList";
// 本地过期时间
const expireTime = "openExpireAt";
// 本地翻译可选项
const langEnum = [
  {
    label: "中文",
    type: "zh",
    isCheck: false,
    icon: require("../assets/images/public/zh.png"),
    alias: "中文",
  },
  {
    label: "English",
    type: "en",
    isCheck: false,
    icon: require("../assets/images/public/en.png"),
    alias: "英文",
  },
  {
    label: "русский язык",
    type: "ru-RU",
    isCheck: false,
    icon: require("../assets/images/public/ru.png"),
    alias: "俄语",
  },
  {
    label: "Türkçe",
    type: "tr-TR",
    isCheck: false,
    icon: require("../assets/images/public/tr.png"),
    alias: "土耳其语",
  },
  {
    label: "Español",
    type: "es",
    isCheck: false,
    icon: require("../assets/images/public/es.png"),
    alias: "西班牙语",
  },
];
export {
  title,
  baseUrl,
  meunNav,
  tokenName,
  skinName,
  defaultSkin,
  reLoadName,
  navMode,
  tableItemName,
  useRouteCache,
  layoutMode,
  logo,
  langFlag,
  langEnum,
  menuListName,
  expireTime,
};
